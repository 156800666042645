<template lang="pug">
b-modal#modal-categorySet(
  v-model="showSubType"
  title="子分類設定",
  title-class="font-18", 
  body-class="p-2 p-lg-4" 
  size="md"
)
  label.mb-3 大分類：{{ currentMainTypeName }}
  .category-list
    draggable(v-model="dataList", group="people", @start="drag=true", @end="drag=false")
      .category-item.ml-1.mb-1(v-for="item of dataList" :key="dataList.id")
        .icon-unit
          i.fas.fa-grip-vertical
        .ipt-box.col-lg-2
          input.form-control(type="text", v-model="item.name", placeholder="請輸入分類", aria-label="Username", aria-describedby="basic-addon1")
  .add-new
    b-button.action-btn.width-lg.mr-1.mt-3(variant="outline-dark" @click="addNewType") ＋新增分類
  template(#modal-footer='{ ok, cancel, hide }')
    b-button.action-btn(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button.action-btn(size='md' variant='success' @click='clickOk()')
      | 確認
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: {
    subTypes: {
      type: Array,
      default: () => []
    },
    currentMainTypeId: {
      type: String,
      required: true
    },
    currentMainTypeName: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataList: [],
      showSubType: false
    }
  },
  methods:{
    addNewType(){
      const newOne = {
        name:'',
        sort: this.dataList.length
      }
      this.dataList.push(newOne)
    },
    checkInput() {
      return this.dataList.every(_data => _data.name !== '')
    },
    clickOk() {
      if(!this.checkInput()) {
        return this.$root.common.showErrorDialog('請確認分類名稱都有填寫喔！')
      }
      const vm = this
      const setData = {
        items: this.dataList.map((_data, _index) => ({
          id: _data.id,
          name: _data.name,
          sort: _index
        }))
      }
      this.$root.apis.putSubTypeCategory(this.currentMainTypeId, setData,
        function(_response) {
          vm.$root.common.showSingleNotify('已儲存')
          vm.$emit('input', false)
        },
        function(_error) {
          console.error(_error.body.data)
        }
      )
    }
  },
  watch: {
    subTypes(_val) {
      this.dataList = JSON.parse(JSON.stringify(_val))
    },
    value(_val) {
      this.showSubType = _val
    }
  },
  components: {
    draggable
  },
}
</script>
<style lang="sass" scoped>
.category-list
  .category-item
    width: 100%
    display: flex
    justify-content: space-start
    align-items: center
    .icon-unit
      font-size: 1.3rem
    .form-control
      font-size: .875rem
      min-width: 200px
    .addbtn
      border: 1px solid #000

@media (max-width: 992px)
  .category-list
    .category-item
      .form-control
        min-width: 100px
  .action-btn
      display: block
      width: 100%
      margin: 0.5rem 0
</style>
