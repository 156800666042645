<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  CategorySet(
    v-model="showSubType"
    :sub-types="subTypes" 
    :current-main-type-id="currentMainTypeId"
    :current-main-type-name="currentMainTypeName")
  .row
    .col-lg-12
      .category-list
        draggable(v-model="types", group="people", @start="drag=true", @end="drag=false")
          .category-item.ml-3.mb-1(v-for="(typeItem, index) of types" :key="typeItem.id")
            .icon-unit
              i.fas.fa-grip-vertical
            .ipt-box.col-lg-2
              input.form-control(type="text", v-model="typeItem.name", placeholder="請輸入分類", aria-label="Username", aria-describedby="basic-addon1")
            b-button.addbtn.ml-2.mb-0.mt-0(
              v-if="typeItem.id.substring(0,4) === 'NEW-'"
              size="md", 
              variant="outline-secondary" 
              @click="createMainCategory(index)"
            ) ＋建立主分類
            b-button.addbtn.ml-2.mb-0.mt-0(
              v-else
              size="md", 
              variant="outline-secondary" 
              v-b-modal.modal-categorySet 
              @click="onEditSubType(typeItem.id)"
            ) ＋編輯子分類
      .add-new
        b-button.action-btn.width-lg.mr-1.mt-3(variant="outline-dark" @click="addNewType") ＋新增分類
      b-button.action-btn.width-lg.mr-1.mt-3(variant="primary" @click="onSave" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import draggable from 'vuedraggable'
import CategorySet from "@/components/CategorySet";

/**
 * Starter component
 */
export default {
  name: "NewsTypes",
  data () {
    return {
      title: "分類文字設定",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "分類文字設定",
          active: true,
        }
      ],
      types: [],
      subTypes: [],
      currentMainTypeId: '',
      showSubType: false,
      saving: false
    };
  },
  created () {
    this.getNewsTypeLevel01List()
  },
  computed: {
    currentMainTypeName() {
      return this.types.find(_type => _type.id === this.currentMainTypeId)?.name || ''
    }
  },
  methods: {
    // 獲得新聞分類清單
    getNewsTypeLevel01List () {
      let vm = this
      this.$root.apis.getNewsTypeLevel01List(function (_response) {
        vm.types = _response.body.data
      })
    },
    onEditSubType(_mainTypeId) {
      const vm = this
      this.$root.apis.getNewsTypeLevel02List(_mainTypeId, function(_response) {
        vm.subTypes = _response.body.data.sub_types
        vm.currentMainTypeId = _mainTypeId
        vm.showSubType = true
      })
    },
    // 新增一個新聞分類
    addNewType () {
      const newOne = {
        id: `NEW-${Math.floor(Math.random() * 1000)}-${this.types.length}`,
        name: '',
        sort: this.types.length + 1,
      }

      this.types.push(newOne)
    },
    // 儲存主分類
    createMainCategory(_index) {
      if (this.types[_index].name === '') {
        return this.$root.common.showErrorDialog('請確認主分類名稱有填寫喔！')
      }
      // create
      const newOne = {
        name: this.types[_index].name,
        sort: this.types[_index].sort
      }
      const vm = this
      this.$root.apis.createMainCategory(
        newOne,
        function(_response){
          vm.types.splice(_index, 1, {
            ...vm.types[_index],
            id: _response.body.data
          })
        }, function(_error) {
          console.error(_error.body.data)
        })
    },
    onSave() {
      const setData = {
        types: this.types.map((_type, _index) => {
          return {
            id: _type.id,
            name: _type.name,
            sort: _index + 1,
            action_type: "type",
            uri: "",
            root: true,
            sub_types: []
          }
        })
      }
      this.saving = true
      const vm = this
      this.$root.apis.sortMainCategory(setData, 
        function(_response) {
          vm.$root.common.showSingleNotify('已儲存')
          vm.saving = false
        },
        function(_error) {
          console.error(_error.body.data)
          vm.$root.common.showErrorNotify(_error.body.data)
          vm.saving = false
        }
      )
    }
  },
  components: {
    Layout,
    PageHeader,
    draggable,
    CategorySet
  },
};
</script>

<style lang="sass" scoped>
.row
  .category-list
    .category-item
      display: flex
      justify-content: space-start
      align-items: center
      .icon-unit
        font-size: 1.3rem
      .form-control
        font-size: .875rem
      .addbtn
        border: 1px solid #000
@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 0.5rem 0
</style>
